import { Component, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import {
  SetFiltersFromUrlQueryParams,
  SetUrlQueryParamsFromState
} from '@mg-platform/core/core-data-access'
import { Store } from '@ngxs/store'
import { isEmpty } from 'lodash'
import { filter, map } from 'rxjs'
import packageJson from '../../../../package.json'

@Component({
  selector: 'mg-admin-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    console.log(`Market Genie Version: ${packageJson.version}`)

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map(() => {
          return this.router.routerState.root.snapshot.queryParams
        })
      )
      .subscribe((params) => {
        if (!isEmpty(params) && params['filters']) {
          this.store.dispatch(new SetFiltersFromUrlQueryParams(params['filters']))
        } else {
          this.store.dispatch(new SetUrlQueryParamsFromState())
        }
      })
  }
}
