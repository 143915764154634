<div class="bg-contrast h-full w-full relative flex flex-col overflow-auto">
  <mg-range-date-picker
    focusInput="start"
    [value]="data.value"
    [maxDate]="data.maxDate"
    [hideAllPeriods]="data.hideAllPeriods"
    [hiddenPeriods]="data.hiddenPeriods"
    (valueChanged)="dialogRef.close($event)"
    (canceld)="dialogRef.close()"
  ></mg-range-date-picker>
</div>
