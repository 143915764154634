import { ICountry } from '@mg-platform/core/core-data-access'
import { ReferralType } from '../enums/referral-type.enum'
import { Role } from '../enums/role.enum'
import { UserPermissionType } from '../enums/user-permission-type.enum'
import { UserRoleInShop } from '../enums/user-role-in-shop.enum'
import { OrganizationFeatures } from '../enums/organization-features.enum'

export type UserGroupType = 'all' | 'pending'

export interface IUserOrganization {
  name: string
  ownerName: string
  ownerUserId: string
}

export interface IUser {
  userName: string
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  hasShops: boolean
  organizationShopNumber: number
  country: ICountry
  role: Role
  permissions?: UserPermissionType[]
  organizationFeaturePermissions: OrganizationFeatures[]
  isOrganizationOwner: boolean
  referralType: ReferralType
  organization?: IUserOrganization
}

export interface IBaseUser {
  id?: string
  firstName?: string
  lastName?: string
  fullName?: string
  phoneNumber?: string
  email?: string
  roleInShop?: UserRoleInShop
  numberOfShops?: number
  isActive: boolean
}

export interface IOrganizationUser extends IBaseUser {
  lastLogin: Date
  pendingConfirmation: boolean
  shops: string[]
  canInviteOthers: boolean
  numberOfShops: number
}

export interface IOrganizationUsersResponse {
  users: IOrganizationUser[]
}

export interface IValidateEmailResponse {
  emailExists: boolean
}

export interface ITokenResponse {
  token: string
  tokenExpirationDate: Date
}

export interface IJWTToken {
  userEmail: string
  role: Role
  userPermissions: UserPermissionType[]
  userIsOwner: string
  organizationFeaturePermissions: OrganizationFeatures[]
}
