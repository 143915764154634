import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { IShop, ShopsState } from '@mg-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { IFilterFormValues } from '../filter-form/filter-form.interface'
import { PageFilterHeaderTab } from './page-filter-header.interface'
import { environment } from '@mg-platform/core/core-util'
import { FiltersState, ICompareWithType } from '@mg-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'mg-page-filter-header',
  templateUrl: './page-filter-header.component.html',
  styleUrls: ['./page-filter-header.component.scss'],
  standalone: false
})
export class PageFilterHeaderComponent implements OnInit {
  shop$: Observable<IShop> = inject(Store).select(ShopsState.shop)
  shop?: IShop
  groupId$: Observable<string> = inject(Store).select(FiltersState.groupId)

  @Input() selectedTab: PageFilterHeaderTab
  @Input() title: string
  @Input() subTitle: string
  @Input() showOrganizationAverage = !environment.production || environment.name === 'app'
  @Input() showGroup = true
  @Input() hiddenCompareWithSections: ICompareWithType[] = []

  @Output() filterChanged = new EventEmitter<IFilterFormValues>()

  showVerifiedBanner = false

  ngOnInit(): void {
    this.shop$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res) {
        this.shop = undefined
      } else if (res && (!this.shop || this.shop?.id !== res.id)) {
        this.shop = res
        this.showVerifiedBanner = !this.shop.isVerified
      }
    })

    this.groupId$.pipe(untilDestroyed(this)).subscribe((res) => {
      if (res) {
        this.showVerifiedBanner = false
      }
    })
  }

  onFilterChanged(filterValues: IFilterFormValues) {
    if (!filterValues) {
      return
    }
    setTimeout(() => {
      this.filterChanged.emit(filterValues)
    })
  }
}
