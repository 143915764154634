import { Component, Input, OnChanges } from '@angular/core'
import { CommonService } from '@mg-platform/core/core-util'
import { UntilDestroy } from '@ngneat/until-destroy'

export interface IPieChartItem {
  value: number
  colorClass: string
  dasharray?: number
  dashoffset?: number
}

@UntilDestroy()
@Component({
  selector: 'mg-ring-chart',
  templateUrl: './ring-chart.component.html',
  styleUrls: ['./ring-chart.component.scss'],
  standalone: false
})
export class RingChartComponent implements OnChanges {
  @Input() size = 200
  @Input() ringWidth = 10
  @Input() info: IPieChartItem

  radius = 0
  randomId = ''
  rotateDeg = 90

  constructor(private commonService: CommonService) {}

  ngOnChanges(): void {
    this.randomId = this.commonService.generateRandomString()
    this.radius = this.size / 2 - this.ringWidth / 2

    if (this.info.value < 100) {
      const dasharray = 2 * 3.14 * this.radius
      this.info = {
        ...this.info,
        dasharray,
        dashoffset: dasharray * ((100 - this.info.value) / 100)
      }
    } else {
      this.rotateDeg = 90 + ((this.info.value - 100) * 360) / 100
    }
  }
}
