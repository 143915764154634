import { Component, Input, OnInit } from '@angular/core'
import { ThemeModeService } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

export type DynamicImageKey =
  | 'boy-checking'
  | 'dashboard-boy'
  | 'dashboard-chart'
  | 'dashboard-chart-mobile'
  | 'dashboard-man'
  | 'no-report'
  | 'not-enough-data'
  | 'not-verified'
  | 'dashboard-ring'
  | 'dashboard-bar'

@UntilDestroy()
@Component({
  selector: 'mg-dynamic-images',
  templateUrl: './dynamic-images.component.html',
  styleUrls: ['./dynamic-images.component.scss'],
  standalone: false
})
export class DynamicImagesComponent implements OnInit {
  @Input() key: DynamicImageKey

  isDarkMode = false
  colorPalette = '1'

  imageSrc = ''

  constructor(private themeModeService: ThemeModeService) {}

  ngOnInit(): void {
    this.themeModeService.isDarkMode.pipe(untilDestroyed(this)).subscribe((res) => {
      this.isDarkMode = res
      this.colorPalette = this.themeModeService.userColorPalette() ?? '1'

      this.imageSrc = `assets/images/dynamic/palette${this.colorPalette}/${
        this.isDarkMode ? 'dark' : 'light'
      }/${this.key}.svg`
    })
  }
}
