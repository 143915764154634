<div class="flex items-center cursor-pointer {{ extraClass }}" (click)="changed.emit(!value)">
  <input
    type="checkbox"
    class="pointer-events-none appearance-none h-xl w-xl rounded-sm border border-gray-500 focus:outline-none"
    style="box-shadow: none !important"
    [ngClass]="{ hidden: value }"
    [checked]="value"
    [disabled]="disabled"
  />
  <div
    *ngIf="value"
    class="pointer-events-none h-xl w-xl rounded-sm flex items-center justify-center text-white bg-primary"
  >
    <svg-icon class="check-icon pointer-events-none" key="tick" class="!text-xs"></svg-icon>
  </div>

  <div
    class="flex-1 ml-sm text-txt-secondary text-base leading-base {{ labelClass }}"
    [ngClass]="{ '!text-txt-disabled': disabled && disableColorChange }"
  >
    {{ label }}
  </div>
</div>
