import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Sort } from '@angular/material/sort'
import { ITableFilter } from '@mg-platform/core/core-feature'
import { MatMenuTrigger } from '@angular/material/menu'

export interface ITableSortInfo extends Sort {
  type: 'value' | 'percentage'
}

@UntilDestroy()
@Component({
  selector: 'mg-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss'],
  standalone: false
})
export class TableSortComponent {
  @ViewChild('sortMenuTrigger') sortMenuTrigger: MatMenuTrigger

  @Input({ required: true }) item: ITableFilter
  @Input() sortable = true
  @Input() onlySort = false
  @Input() sortInfo?: ITableSortInfo
  @Input() rightAlign = false
  @Input() containerClass = ''

  @Output() sortChanged = new EventEmitter<ITableSortInfo | undefined>()

  emitSortChange(info: ITableSortInfo) {
    if (!this.sortable) {
      return
    }
    this.sortChanged.emit(
      info.active === this.sortInfo?.active &&
        info.direction === this.sortInfo?.direction &&
        info.type === this.sortInfo?.type
        ? undefined
        : info
    )
    this.sortMenuTrigger.closeMenu()
  }
}
