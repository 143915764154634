import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { ISelectItem } from '../input/input.interface'
import { DecimalPipe } from '@angular/common'

export interface ITimePickerValue {
  hour: number
  minute: number
  period: 'AM' | 'PM'
}

@UntilDestroy()
@Component({
  selector: 'mg-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [DecimalPipe],
  standalone: false
})
export class TimePickerComponent implements OnInit {
  @Input() value: ITimePickerValue
  @Output() timeChanged = new EventEmitter<ITimePickerValue>()

  form: UntypedFormGroup
  hourItems: ISelectItem[] = []
  minuteItems: ISelectItem[] = []
  periodItems: ISelectItem[] = []

  constructor(
    private fb: FormBuilder,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.hourItems = Array(12)
      .fill(1)
      .map((el, index) => ({
        label: `${this.decimalPipe.transform(index + 1, `2.0-0`)}`,
        value: index + 1
      }))

    this.minuteItems = Array(60)
      .fill(1)
      .map((el, index) => ({
        label: `${this.decimalPipe.transform(index, `2.0-0`)}`,
        value: index
      }))

    this.periodItems = [
      { label: 'AM', value: 'AM' },
      { label: 'PM', value: 'PM' }
    ]

    this.form = this.fb.group({
      hour: [this.value?.hour ?? 1],
      minute: [this.value?.minute ?? 0],
      period: [this.value?.period ?? 'AM']
    })

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((values) => {
      if (values) {
        this.timeChanged.emit(values)
      }
    })
  }
}
