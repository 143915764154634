import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { SwiperOptions } from 'swiper'
import SwiperCore, { Pagination } from 'swiper'

SwiperCore.use([Pagination])

@UntilDestroy()
@Component({
  selector: 'mg-element-slider',
  templateUrl: './element-slider.component.html',
  styleUrls: ['./element-slider.component.scss'],
  standalone: false
})
export class ElementSliderComponent {
  @Input() numberOfSlots = 2
  config: SwiperOptions = {
    slidesPerView: 1.1,
    centeredSlides: true,
    spaceBetween: 10,
    pagination: { clickable: true }
  }
}
