import { Component, HostListener, Input, OnInit } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-responsive-switcher',
  templateUrl: './responsive-switcher.component.html',
  styleUrls: ['./responsive-switcher.component.scss'],
  standalone: false
})
export class ResponsiveSwitcherComponent implements OnInit {
  @Input() breakPoint: 'md' | 'lg' = 'md'
  @Input() fullHeight = false
  @Input() isPrint = false

  isMobileVersion = false

  ngOnInit(): void {
    this.shouldShowMobileVersion()
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.shouldShowMobileVersion()
  }

  shouldShowMobileVersion() {
    const innerWidth = window.innerWidth

    this.isMobileVersion =
      (this.breakPoint === 'md' && innerWidth < 768) || (this.breakPoint === 'lg' && innerWidth < 976)
  }
}
