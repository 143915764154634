export interface ISalesReportItem {
  day?: Date
  sales: number
  cars: number
  color?: string
  time: string
}

export interface ISalesReportGroupItem {
  shopId: string
  items: ISalesReportItem[]
}

export interface ISalesReportResponse {
  groups: ISalesReportGroupItem[]
}
