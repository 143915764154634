import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { ThemeModeService } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-update-theme-card',
  templateUrl: './update-theme-card.component.html',
  styleUrls: ['./update-theme-card.component.scss'],
  standalone: false
})
export class UpdateThemeCardComponent implements OnInit {
  @Input() extraClasses = ''

  form: UntypedFormGroup

  darkModeTypes: ISelectItem[] = [
    {
      label: 'Dark',
      value: 'dark'
    },
    {
      label: 'Light',
      value: 'light'
    },
    {
      label: 'System',
      value: 'system'
    }
  ]

  colorPalettes: ISelectItem[] = [
    {
      label: 'Purple',
      value: '1',
      extraInfo: ['#9673D3', '#EBBC00', '#E07266', '#73D396', '#D1D8DB']
    },
    {
      label: 'Blue',
      value: '2',
      extraInfo: ['#5CAFE2', '#FF6F61', '#E25CAF', '#ADCB01', '#D1D8DB']
    },
    {
      label: 'Teal',
      value: '3',
      extraInfo: ['#00BECC', '#FF9514', '#CC00BE', '#92CC00', '#D1D8DB']
    },
    {
      label: 'Green',
      value: '4',
      extraInfo: ['#6FA6A0', '#DBD65C', '#A06FA6', '#A6A06F', '#D1D8DB']
    },
    {
      label: 'Brown',
      value: '5',
      extraInfo: ['#9F6255', '#A5CD60', '#599CA6', '#62559F', '#D1D8DB']
    }
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private themeModeService: ThemeModeService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      type: [this.themeModeService.userDarkModeType() ?? 'system'],
      palette: [this.themeModeService.userColorPalette() ?? '1']
    })

    this.form
      .get('type')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.themeModeService.changeDarkMode(value !== 'system' ? value : undefined)
        }
      })

    this.form
      .get('palette')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.themeModeService.changeColorPalette(value)
        }
      })
  }
}
