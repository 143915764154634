<div
  [style.display]="state().value === 'inactive' ? 'none' : ''"
  class="flex items-center px-lg py-base bg-black dark:bg-white text-white dark:text-black"
  [ngClass]="{ '!bg-error !text-white': toastPackage.toastType === 'toast-error' }"
>
  <div *ngIf="toastPackage.toastType === 'toast-error'" class="flex mr-sm">
    <svg-icon key="warning-diamond" class="!text-base"></svg-icon>
  </div>
  <div class="flex-1 text-base leading-base">
    <div
      *ngIf="message && options.enableHtml"
      [class]="options.messageClass"
      [innerHTML]="message"
    ></div>
    <div
      *ngIf="message && !options.enableHtml"
      [class]="options.messageClass"
      [attr.aria-label]="message"
    >
      {{ message }}
    </div>
  </div>
  <div *ngIf="options.closeButton" class="ml-xl flex cursor-pointer">
    <svg-icon key="close" class="!text-sm"></svg-icon>
  </div>
</div>
