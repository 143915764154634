import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import {
  GetMarketPricingThresholds,
  IMarketPricingThresholds,
  MarketPricingReportsState,
  UpdateMarketPricing
} from '@mg-platform/reports/reports-data-access'
import { ShopsState } from '@mg-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { finalize } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-add-shop-service-price',
  templateUrl: './add-shop-service-price.component.html',
  styleUrls: ['./add-shop-service-price.component.scss'],
  standalone: false
})
export class AddShopServicePriceComponent implements OnInit {
  @Input() addNewShop = false
  @Output() completed = new EventEmitter()

  shopId: string
  form: UntypedFormGroup
  isProcessing = false
  isLoadingGetThresholds = false
  thresholds: IMarketPricingThresholds

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.getThresholds()

    this.shopId = this.store.selectSnapshot(ShopsState.shop)?.id
  }

  getThresholds() {
    this.isLoadingGetThresholds = true
    this.store
      .dispatch(new GetMarketPricingThresholds())
      .pipe(
        untilDestroyed(this),
        finalize(() => (this.isLoadingGetThresholds = false))
      )
      .subscribe(() => {
        this.thresholds = this.store.selectSnapshot(MarketPricingReportsState.thresholds)

        this.form = this.fb.group({
          laborRate: [
            null,
            [
              Validators.required,
              this.thresholds?.minLaborRate ? Validators.min(this.thresholds.minLaborRate) : undefined,
              this.thresholds?.maxLaborRate ? Validators.max(this.thresholds.maxLaborRate) : undefined
            ].filter((el) => !!el)
          ],
          alingment: [
            null,
            [
              Validators.required,
              this.thresholds?.minAlignment ? Validators.min(this.thresholds.minAlignment) : undefined,
              this.thresholds?.maxAlignment ? Validators.max(this.thresholds.maxAlignment) : undefined
            ].filter((el) => !!el)
          ],
          oilChange: [
            null,
            [
              Validators.required,
              this.thresholds?.minOilChange ? Validators.min(this.thresholds.minOilChange) : undefined,
              this.thresholds?.maxOilChange ? Validators.max(this.thresholds.maxOilChange) : undefined
            ].filter((el) => !!el)
          ]
        })
      })
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.invalid || this.isProcessing) {
      return
    }

    this.isProcessing = true
    this.store
      .dispatch(new UpdateMarketPricing(this.form.value, this.shopId))
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isProcessing = false
        })
      )
      .subscribe(() => {
        this.completed.emit()
      })
  }
}
