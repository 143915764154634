import { Component, inject, Input, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { IUser, Logout, UserAccountState } from '@mg-platform/users/users-data-access'
import { Observable } from 'rxjs'
import { ResetShopState } from '@mg-platform/shops/shops-data-access'
import { ResetFilters } from '@mg-platform/core/core-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { IHeaderRouteInfo } from '../main-header/main-header.interface'

@UntilDestroy()
@Component({
  selector: 'mg-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
  standalone: false
})
export class TopMenuComponent implements OnInit {
  @Input() profileMenuRoutes: IHeaderRouteInfo[]
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)
  currentUser: IUser

  constructor(
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUser$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.currentUser = res
    })
  }

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.store.dispatch([
        new ResetShopState(),
        new ResetFilters(['shopId', 'marketType', 'weekNumber', 'compareWith'])
      ])
      this.router.navigateByUrl('/login')
    })
  }
}
