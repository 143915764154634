import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-week-selector',
  templateUrl: './week-selector.component.html',
  styleUrls: ['./week-selector.component.scss'],
  standalone: false
})
export class WeekSelectorComponent {
  @Input() availableWeeks: ISelectItem[] = []
  @Input() selectedWeekNumber: number
  @Output() selectedWeekChanged = new EventEmitter<number>()
}
