import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { CountryCode, GetStates, IState, StatesState } from '@mg-platform/core/core-data-access'
import { ISelectItem, IShopExtractedInfo } from '@mg-platform/core/core-ui'
import { PhoneNumberValidator, urlValidator } from '@mg-platform/core/core-util'
import { CreateShop } from '@mg-platform/shops/shops-data-access'
import { GetCurrentUser, RefreshToken, UserAccountState } from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { concatMap, finalize, Observable } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-add-shop',
  templateUrl: './add-shop.component.html',
  styleUrls: ['./add-shop.component.scss'],
  standalone: false
})
export class AddShopFormComponent implements OnInit {
  @Input() addNewShop = false
  @Input() cancelBtnLabel: string
  @Output() completed = new EventEmitter()
  @Output() canceld = new EventEmitter()

  $states: Observable<IState[]> = inject(Store).select(StatesState.states)

  userCountryCode?: CountryCode
  googlePlacesCountries: CountryCode[] = []
  form: UntypedFormGroup
  googlePlaceForm: UntypedFormGroup
  isProcessing = false
  selectedStateAbbreviation?: string
  states: ISelectItem[] = []
  allStates: IState[] = []
  loadingStates = false
  activeSection: 'place' | 'details' | 'contact' = 'place'
  allCountries: ISelectItem[] = [
    {
      label: 'United States',
      value: 'US'
    },
    {
      label: 'Canada',
      value: 'CA'
    }
  ]

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store
  ) {}

  ngOnInit() {
    this.userCountryCode = this.store.selectSnapshot(UserAccountState.currentUser)?.country?.countryCode
    this.googlePlacesCountries = this.userCountryCode
      ? [this.userCountryCode]
      : [CountryCode.USA, CountryCode.CA]
    this.$states.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allStates = res
      this.setStateItems()
      this.findAbbreviationState()
    })

    this.form = this.fb.group({
      name: [null, Validators.required],
      nickname: [null],
      address: [null, Validators.required],
      city: [null, Validators.required],
      countryCode: [null, Validators.required],
      postCode: [null, [Validators.required]],
      stateId: [null, Validators.required],
      phoneNumber: [null, [Validators.required, PhoneNumberValidator('US')]],
      websiteUrl: [null, [Validators.required, urlValidator]],
      numberOfBays: [null, [Validators.required, Validators.max(50), Validators.min(1)]],
      lat: [0],
      long: [0]
    })

    this.form
      .get('countryCode')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.get('stateId')?.reset()
        this.setStateItems()
      })

    this.googlePlaceForm = this.fb.group({
      location: [null]
    })
  }

  addManually() {
    this.form.reset()
    this.setCountryCode()
    this.showCreateShopForm()
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.invalid || this.isProcessing) {
      return
    }

    this.isProcessing = true
    this.store
      .dispatch(new CreateShop({ ...this.form.value, countryCode: undefined }))
      .pipe(
        concatMap(() => this.store.dispatch(new RefreshToken())),
        concatMap(() => this.store.dispatch(new GetCurrentUser())),
        untilDestroyed(this),
        finalize(() => {
          this.isProcessing = false
        })
      )
      .subscribe(() => {
        this.completed.emit()
      })
  }

  showCreateShopForm() {
    this.activeSection = 'details'

    this.loadingStates = true
    this.store
      .dispatch(new GetStates())
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.loadingStates = false
        })
      )
      .subscribe()
  }

  shopAddressSelected(info: IShopExtractedInfo) {
    this.selectedStateAbbreviation = info.stateAbbreviation
    this.form.patchValue({
      ...info
    })
    this.setCountryCode()
    this.showCreateShopForm()
  }

  setCountryCode() {
    const countryCodeControl = this.form.get('countryCode')
    if (this.userCountryCode) {
      countryCodeControl?.setValue(
        this.userCountryCode === CountryCode.USA ? 'US' : this.userCountryCode
      )
      countryCodeControl?.disable()
    } else {
      countryCodeControl?.enable()
    }
  }

  findAbbreviationState() {
    const states = this.store.selectSnapshot(StatesState.states)
    if (this.selectedStateAbbreviation) {
      const stateId = states.find((el) => el.abbreviation === this.selectedStateAbbreviation)?.stateId
      if (stateId) {
        this.form.patchValue({ stateId })
      }
    }
  }

  detailsFormIsInvalid() {
    return (
      !!this.form.get('name')?.invalid ||
      !!this.form.get('address')?.invalid ||
      !!this.form.get('city')?.invalid ||
      !!this.form.get('postCode')?.invalid ||
      !!this.form.get('stateId')?.invalid
    )
  }

  continueToContactForm() {
    this.form.get('name')?.markAsTouched()
    this.form.get('address')?.markAsTouched()
    this.form.get('stateId')?.markAsTouched()
    this.form.get('city')?.markAsTouched()
    this.form.get('postCode')?.markAsTouched()
    if (this.detailsFormIsInvalid()) {
      return
    }
    this.activeSection = 'contact'
  }

  setStateItems() {
    const countryCode: CountryCode = this.form?.get('countryCode')?.value
    this.states = this.allStates
      .filter((el) => !countryCode || el.countryCode.startsWith(countryCode))
      .map((el) => ({
        label: el.stateName,
        value: el.stateId
      }))
  }
}
