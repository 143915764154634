import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  CountryCode,
  ICompareWithSelection,
  ICompareWithType,
  StatesState
} from '@mg-platform/core/core-data-access'
import { values } from 'lodash'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngxs/store'
import { OrganizationFeatures, UserAccountState } from '@mg-platform/users/users-data-access'
import { MarketType } from '@mg-platform/reports/reports-data-access'
import { FeaturesService } from '../../services/features.services'
import { PillSize } from '../pill/pill.component'

@UntilDestroy()
@Component({
  selector: 'mg-compare-with',
  templateUrl: './compare-with.component.html',
  styleUrls: ['./compare-with.component.scss'],
  standalone: false
})
export class CompareWithComponent implements OnInit, OnChanges {
  @Input() selections: ICompareWithSelection
  @Input() singleSelector: boolean
  @Input() hiddenSections?: ICompareWithType[] = []
  @Input() pillSize: PillSize = 'm'
  @Input() allShops: ISelectItem[] = []
  @Input() allStates: ISelectItem[] = []
  @Input() allRegions: ISelectItem[] = []
  @Input() allNationals: ISelectItem[] = []
  @Input() allGroups: ISelectItem[] = []
  @Input() organizationAverageIsSelected: boolean
  @Output() selectionsChanged = new EventEmitter<ICompareWithSelection>()
  @Output() canceld = new EventEmitter()

  visibleShops: ISelectItem[] = []
  visibleStates: ISelectItem[] = []
  visibleRegions: ISelectItem[] = []
  visibleNationals: ISelectItem[] = []
  visibleGroups: ISelectItem[] = []
  showOrganizationAverage = true

  marketTypes = MarketType
  showAllShops = false
  showAllStates = false
  showAllRegions = false
  numberOfSelection = 1
  numberOfSelectedShops = 0
  organizationHasMoreThanOneShop = false
  userCountry: CountryCode
  selectedCountry: CountryCode
  countryCodes = CountryCode
  isOrganizationOwner = false
  showCountrySwitcher = false

  groupTabSelected = false
  showGroupSwitcher = false
  showGroupSection = false

  form: UntypedFormGroup
  searchInputId = 'compare_with_selector_search_input'

  shopGroupsPagePath = '/shops/groups'

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    public featuresService: FeaturesService
  ) {
    this.userCountry = this.store.selectSnapshot(StatesState.userCountry)
    this.selectedCountry = this.userCountry === CountryCode.ALL ? CountryCode.USA : this.userCountry
  }

  ngOnInit(): void {
    const currentUser = this.store.selectSnapshot(UserAccountState.currentUser)
    this.organizationHasMoreThanOneShop = !!(currentUser?.organizationShopNumber > 1)
    this.isOrganizationOwner = currentUser?.isOrganizationOwner

    this.showGroupSection =
      !this.hiddenSections?.includes('groups') &&
      this.organizationHasMoreThanOneShop &&
      (this.isOrganizationOwner || this.allGroups.length > 0) &&
      this.featuresService.checkHasPermission(OrganizationFeatures.Groups)

    this.showGroupSwitcher = this.showGroupSection && !this.hiddenSections?.includes('shops')

    this.showCountrySwitcher =
      this.userCountry === CountryCode.ALL &&
      (!this.hiddenSections?.includes('states') || !this.hiddenSections?.includes('regions'))

    this.form = this.fb.group({
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        const trimValue = value?.toLowerCase().trim() ?? ''
        this.setAllVisibleItems(trimValue)
      })
  }

  ngOnChanges(): void {
    if (this.singleSelector) {
      this.form?.reset()
    }
    this.setAllVisibleItems()
    this.calculateNumberOfSelections()
  }

  toggleSelection(value: string, key: ICompareWithType) {
    if (this.singleSelector) {
      this.resetSelection()
      this.selectionsChanged.emit({ ...this.selections, [key]: [value] })
    } else {
      let items = this.selections[key]
      if (items.includes(value)) {
        items = items.filter((el: string) => el !== value)
      } else {
        items.push(value)
      }
      this.selections = { ...this.selections, [key]: items }
      this.calculateNumberOfSelections()
    }
  }

  saveSelections() {
    if (this.numberOfSelection < 1) {
      return
    }
    this.selectionsChanged.emit({ ...this.selections })
  }

  calculateNumberOfSelections() {
    this.numberOfSelection =
      values(this.selections).flat().length - (!this.selections.organizationAverage ? 1 : 0)

    this.numberOfSelectedShops =
      (this.selections?.shops ?? []).length + (this.selections.organizationAverage === true ? 1 : 0)
  }

  resetSelection() {
    this.selections = {
      organizationAverage: false,
      shops: [],
      regions: [],
      states: [],
      nationals: [],
      groups: []
    }
    this.calculateNumberOfSelections()
  }

  toggleAllShops() {
    if (
      this.numberOfSelectedShops <
      this.allShops.length + (this.organizationAverageIsSelected ? 0 : 1)
    ) {
      this.selections = {
        ...this.selections,
        organizationAverage: !this.organizationAverageIsSelected,
        shops: this.allShops.map((el) => el.value)
      }
    } else {
      this.selections = {
        ...this.selections,
        organizationAverage: false,
        shops: []
      }
    }

    this.calculateNumberOfSelections()
  }

  setOrganizationAverage() {
    if (this.singleSelector) {
      this.resetSelection()
      this.selectionsChanged.emit({ ...this.selections, organizationAverage: true })
    } else {
      this.selections.organizationAverage = !this.selections.organizationAverage
      this.calculateNumberOfSelections()
    }
  }

  setAllVisibleItems(searchText?: string) {
    if (searchText && searchText !== '') {
      this.showOrganizationAverage = 'organizations average'.includes(searchText)
      this.visibleShops = this.allShops.filter((el) => el.label.toLowerCase().includes(searchText))
      this.visibleNationals = this.allNationals.filter((el) =>
        el.label.toLowerCase().includes(searchText)
      )
      this.visibleGroups = this.allGroups.filter((el) => el.label.toLowerCase().includes(searchText))
    } else {
      this.showOrganizationAverage = true
      this.visibleShops = this.allShops
      this.visibleNationals = this.allNationals
      this.visibleGroups = this.allGroups
    }

    this.setVisibleStatesAndRegions()
  }

  setVisibleStatesAndRegions() {
    const searchTextValue: string = this.form?.get('searchText')?.value
    const searchText = searchTextValue?.toLowerCase().trim() ?? ''
    const countryState = this.allStates.filter((el) => el.extraInfo === this.selectedCountry)
    const countryRegions = this.allRegions.filter((el) => el.extraInfo === this.selectedCountry)
    if (searchText && searchText !== '') {
      this.visibleStates = countryState.filter((el) => el.label.toLowerCase().includes(searchText))
      this.visibleRegions = countryRegions.filter((el) => el.label.toLowerCase().includes(searchText))
    } else {
      this.visibleStates = countryState
      this.visibleRegions = countryRegions
    }
  }

  public setFocusOnSearchInput() {
    setTimeout(() => {
      document.getElementById(this.searchInputId)?.focus()
    })
  }
}
