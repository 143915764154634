import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'mg-simple-bar-chart',
  templateUrl: './simple-bar-chart.component.html',
  styleUrls: ['./simple-bar-chart.component.scss'],
  standalone: false
})
export class SimpleBarChartComponent implements OnInit {
  @Input() onlyLines = false
  @Input() precentage = 50
  @Input() label = ''
  @Input() labelClass = ''
  @Input() firstValue = 100
  @Input() firstValueFormatted: string
  @Input() secondValue = 50
  @Input() secondValueFormatted: string
  @Input() firstLabel = 'My Shop'
  @Input() secondLabel = 'Market'
  @Input() withDolarSign = true
  @Input() firstLineColorClass = 'bg-primary'
  @Input() secondLineColorClass = 'bg-graph-2'
  @Input() labelLineDistance = 8
  @Input() hasHoverEffect = true

  firstBarPercentage = 0
  secondBarPercentage = 0

  ngOnInit() {
    const maxValue = Math.max(this.firstValue, this.secondValue)

    this.firstBarPercentage = (this.firstValue / maxValue) * 100
    this.secondBarPercentage = (this.secondValue / maxValue) * 100
  }
}
