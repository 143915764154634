import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-tippy-tooltip',
  templateUrl: './tippy-tooltip.component.html',
  styleUrls: ['./tippy-tooltip.component.scss'],
  standalone: false
})
export class TippyTooltipComponent {
  @Input() clickable = false

  onClick(event: MouseEvent) {
    if (!this.clickable) {
      event.stopPropagation()
    }
  }
}
