import { Component, Input, OnInit } from '@angular/core'
import { MarketType } from '@mg-platform/reports/reports-data-access'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-dynamic-icons',
  templateUrl: './dynamic-icons.component.html',
  styleUrls: ['./dynamic-icons.component.scss'],
  standalone: false
})
export class DynamicIconsComponent implements OnInit {
  @Input() marketType: MarketType
  @Input() extraClass = 'text-txt-secondary mr-sm'
  @Input() boxLike = false
  @Input() boxColor = 'rgba(var(--color-gray-100), 1)'

  icon = 'garage'

  ngOnInit(): void {
    switch (this.marketType) {
      case MarketType.national:
        this.icon = 'flag'
        break

      case MarketType.regional:
        this.icon = 'region'
        break

      case MarketType.state:
        this.icon = 'map'
        break

      case MarketType.group:
        this.icon = 'group'
        break
    }
  }
}
