import { ReferralType } from '../enums/referral-type.enum'

export interface IUserRegisterRequest {
  userName: string
  firstName: string
  lastName: string
  phoneNumber: string
  password: string
  referralType?: ReferralType
  referencedUserId?: string
}

export interface IUserRegistrationStatus {
  isRegistered: boolean
  emailAddress: string
}
