import { AfterViewInit, Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core'

@Directive({
  selector: '[mgIsVisible]',
  standalone: false
})
export class IsVisibleDirective implements AfterViewInit, OnDestroy {
  constructor(
    private vcRef: ViewContainerRef,
    private tplRef: TemplateRef<any>
  ) {}
  isVisible = false
  observer: IntersectionObserver

  ngAfterViewInit() {
    const observedElement = this.vcRef.element.nativeElement.parentElement

    this.observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry.isIntersecting)
      if (this.isVisible) {
        this.observer.unobserve(observedElement)
      }
    })
    this.observer.observe(observedElement)
  }

  ngOnDestroy(): void {
    this.observer.disconnect()
  }

  renderContents(isIntersecting: boolean) {
    this.vcRef.clear()

    if (isIntersecting) {
      this.isVisible = true
      this.vcRef.createEmbeddedView(this.tplRef)
    }
  }
}
