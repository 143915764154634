import { Component, inject, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { ITabsSection } from '@mg-platform/core/core-ui'
import { IUser, Role, UserAccountState } from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { first, Observable } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-profile-layout',
  templateUrl: './profile-layout.component.html',
  styleUrls: ['./profile-layout.component.scss'],
  standalone: false
})
export class ProfileLayoutComponent implements OnInit {
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)

  selectedRoute: string
  sections: ITabsSection[] = [
    {
      key: '/profile/edit-profile',
      title: 'Edit Profile'
    },
    {
      key: '/profile/edit-password',
      title: 'Change Password'
    }
  ]

  constructor(public router: Router) {}

  ngOnInit(): void {
    this.selectedRoute = this.router.url

    this.currentUser$.pipe(untilDestroyed(this), first()).subscribe((currentUser) => {
      if (currentUser?.role === Role.user) {
        this.sections.push({
          key: '/profile/edit-notifications',
          title: 'Notifications Settings'
        })
      }
    })
  }
}
