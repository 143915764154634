<div class="flex items-center">
  @if (pill) {
    <div
      class="px-base md:px-sm py-xs text-base leading-base md:text-sm md:leading-sm text-txt border border-gray-200 hover:bg-ui-hover rounded-full cursor-pointer"
      [ngClass]="{
        'bg-ui-selected !text-white !border-ui-selected hover:!bg-ui-selected': item
          ? formControl.value?.includes(item.value)
          : formControl.value
      }"
      (click)="toggleCheckbox(item?.value)"
    >
      {{ item?.label ?? label }}
    </div>
  } @else {
    <input
      type="checkbox"
      [checked]="item ? formControl.value?.includes(item.value) : formControl.value"
      [disabled]="disabled"
      (click)="toggleCheckbox(item?.value)"
      class="appearance-none h-xl w-xl rounded-sm border border-gray-500 focus:outline-none cursor-pointer"
      [ngClass]="{ hidden: item ? formControl.value?.includes(item.value) : formControl.value }"
    />

    <div
      *ngIf="item ? formControl.value?.includes(item.value) : formControl.value"
      class="h-xl w-xl rounded-sm flex items-center justify-center text-white bg-primary cursor-pointer"
      [ngClass]="{ '!bg-txt-disabled': disabled }"
      (click)="toggleCheckbox(item?.value)"
    >
      <svg-icon class="check-icon pointer-events-none" key="tick" class="!text-xs"></svg-icon>
    </div>

    <div
      class="flex-1 ml-sm text-txt-secondary text-base leading-base cursor-pointer {{ labelClass }}"
      [ngClass]="{ '!text-txt-disabled': disabled && disableColorChange }"
      (click)="toggleCheckbox(item?.value)"
    >
      {{ item?.label ?? label }}
    </div>
  }
</div>
