import { Component } from '@angular/core'
import { Toast } from 'ngx-toastr'

@Component({
  selector: 'mg-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss'],
  preserveWhitespaces: false,
  standalone: false
})
export class CustomToastComponent extends Toast {}
