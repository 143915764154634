import { ReferralType } from '../../enums/referral-type.enum'
import { IUserPreferences } from '../../interfaces/user-preferences.interface'
import { IUserRegistrationStatus } from '../../interfaces/user-register.interface'
import { IOrganizationUser, IUser } from '../../interfaces/user.interface'

export interface UserAccountStateModel {
  user: IUser
  isAuthenticated: boolean
  userPreferences: IUserPreferences
  returnUrl: string
  referralType: ReferralType
  allUsers: IOrganizationUser[]
  selectedUser: IOrganizationUser
  emailExists: boolean
  registrationStatus: IUserRegistrationStatus
}
