import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { PeriodType, PeriodTypeToLabel } from '@mg-platform/core/core-data-access'
import { DateService } from '@mg-platform/core/core-util'

@UntilDestroy()
@Component({
  selector: 'mg-selected-date-title',
  templateUrl: './selected-date-title.component.html',
  styleUrls: ['./selected-date-title.component.scss'],
  standalone: false
})
export class SelectedDateTitleComponent {
  @Input() selectedDateInfo: IRangeDateInfo

  periodType = PeriodType
  periodTypeToLabel = PeriodTypeToLabel

  constructor(public dateService: DateService) {}
}
