import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { SHOPS_API_URL } from './shops-api-routes'
import { ICreateShopRequest } from './interfaces/create-shop.interface'
import { IAdminUpdateShopRequest, IUpdateShopRequest } from './interfaces/update-shop.interface'
import { IAdminShop, IShop, IShopResponse } from './interfaces/shop.interface'
import { IShopUsersResponse } from './interfaces/shop-users.interface'
import { IAdminShopsResponse } from './interfaces/admin-shops-response.interface'
import { DateService } from '@mg-platform/core/core-util'
import { IShopFocusRequest } from './interfaces/shop-focus.interface'
import { IUpdateShopGroup } from './interfaces/shop-groups.interface'
import { ITableAction, TableActionsService } from '@mg-platform/core/core-feature'
import { IUserShopAccessRequest } from './interfaces/user-shop-access.interface'

@Injectable({
  providedIn: 'root'
})
export class ShopsService {
  constructor(
    private http: HttpService,
    private dateService: DateService,
    private tableActionsService: TableActionsService
  ) {}

  /*** User Shops ***/

  createShop(payload: ICreateShopRequest): Observable<IShop> {
    return this.http.post<IShop>(SHOPS_API_URL.base(), payload)
  }

  updateShop(payload: IUpdateShopRequest, shopId: string): Observable<IShop> {
    return this.http.put<IShop>(SHOPS_API_URL.base(shopId), payload)
  }

  setShopFocus(payload: IShopFocusRequest, shopId: string) {
    return this.http.put(SHOPS_API_URL.setShopFocus(shopId), payload)
  }

  getAllUserShops(): Observable<IShopResponse> {
    return this.http.get<IShop[]>(SHOPS_API_URL.base())
  }

  getOneUserShops(shopId: string, withLastReportDate?: boolean): Observable<IShop> {
    return this.http.get<IShop>(SHOPS_API_URL.getOneShop(shopId, withLastReportDate))
  }

  getShopUsers(shopId: string): Observable<IShopUsersResponse> {
    return this.http.get<IShopUsersResponse>(SHOPS_API_URL.getShopUsers(shopId))
  }

  getShopPendingUsers(shopId: string): Observable<IShopUsersResponse> {
    return this.http.get<IShopUsersResponse>(SHOPS_API_URL.getShopPendingUsers(shopId))
  }

  updateUserShopAccess(payload: IUserShopAccessRequest) {
    return this.http.post(SHOPS_API_URL.updateUserShopAccess, payload)
  }

  /*** Shop Groups ***/
  updateShopGroup(payload: IUpdateShopGroup) {
    return this.http.post(SHOPS_API_URL.updateShopGroup, payload)
  }

  deleteShopGroup(groupId: string) {
    return this.http.delete(SHOPS_API_URL.deleteShopGroup(groupId))
  }

  autoCreateShopGroups() {
    return this.http.post(SHOPS_API_URL.autoCreateShopGroups, {})
  }

  /*** Admin Shops ***/

  getAllAdminShops(
    info: ITableAction,
    organizationId?: string,
    userId?: string
  ): Observable<IAdminShopsResponse> {
    let params: { [key: string]: string } = {}
    if (organizationId) {
      params = { organizationId }
    }
    if (userId) {
      params = { ...params, userId }
    }

    return this.http.post<IAdminShopsResponse>(
      SHOPS_API_URL.adminGetAllShops,
      this.tableActionsService.createRequestPayload(info),
      { params }
    )
  }

  exportAllAdminShops(
    info: ITableAction,
    organizationId?: string,
    userId?: string
  ): Observable<IExportFileResponse> {
    let params: { [key: string]: string } = {}
    if (organizationId) {
      params = { organizationId }
    }
    if (userId) {
      params = { ...params, userId }
    }

    return this.http.post(
      SHOPS_API_URL.adminExportAllShops,
      this.tableActionsService.createRequestPayload(info),
      { params }
    )
  }

  getOneAdminShop(shopId: string): Observable<IAdminShop> {
    return this.http.get<IAdminShop>(SHOPS_API_URL.adminShop(shopId))
  }

  updateAdminShop(payload: IAdminUpdateShopRequest, shopId: string): Observable<IAdminShop> {
    return this.http.put<IAdminShop>(SHOPS_API_URL.adminShop(shopId), payload)
  }

  adminVerifyShop(shopId: string) {
    return this.http.post(SHOPS_API_URL.adminVerifyShop(shopId), {})
  }

  adminDeclineShop(shopId: string) {
    return this.http.post(SHOPS_API_URL.adminDeclineShop(shopId), {})
  }

  adminDeleteShop(shopId: string) {
    return this.http.delete(SHOPS_API_URL.adminDeleteShop(shopId))
  }

  adminDeleteShopUser(shopId: string, userId: string) {
    return this.http.delete(SHOPS_API_URL.adminDeleteShopUser(shopId, userId))
  }

  getDailyReportsShops(payload: Date, excludeBatch: boolean): Observable<IAdminShopsResponse> {
    return this.http.get<IAdminShopsResponse>(
      `${
        SHOPS_API_URL.adminDailyReportsShops
      }?excludeBatch=${!!excludeBatch}&date=${this.dateService.convertToDateString(payload)}`
    )
  }
}
