import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { ORGANIZATION_AVERAGE } from '@mg-platform/core/core-util'
import { MarketType } from '@mg-platform/reports/reports-data-access'
import { IShop, ShopGroupsState, ShopsState } from '@mg-platform/shops/shops-data-access'
import { OrganizationFeatures, UserAccountState } from '@mg-platform/users/users-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { groupBy } from 'lodash'
import { Observable } from 'rxjs'
import { FeaturesService } from '../../services/features.services'
import { PillSize } from '../pill/pill.component'

export interface IShopSelectorSelectedItem {
  type: MarketType
  id: string
}

@UntilDestroy()
@Component({
  selector: 'mg-shop-selector',
  templateUrl: './shop-selector.component.html',
  styleUrls: ['./shop-selector.component.scss'],
  standalone: false
})
export class ShopSelectorComponent implements OnInit {
  @Input() selectedId: string
  @Input() pillSize: PillSize = 'm'
  @Input() showOrganizationAverage: boolean
  @Input() showGroup: boolean
  @Output() selectedIdChanged = new EventEmitter<IShopSelectorSelectedItem>()
  @Output() canceld = new EventEmitter()

  $allShops: Observable<IShop[]> = inject(Store).select(ShopsState.allShops)
  allShops: ISelectItem[] = []
  selectedShops: { [key: string]: ISelectItem[] }

  allGroups: ISelectItem[] = []
  selectedGroups: ISelectItem[] = []

  marketTypes = MarketType

  form: UntypedFormGroup
  searchInputId = 'shop_selector_search_input'
  organizationId = ORGANIZATION_AVERAGE.id
  organizationHasMoreThanOneShop = false
  isOrganizationOwner = false
  groupTabSelected = false
  showGroupSwitcher = false
  shopGroupsPagePath = '/shops/groups'

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    private featuresService: FeaturesService
  ) {}

  ngOnInit(): void {
    const currentUser = this.store.selectSnapshot(UserAccountState.currentUser)
    this.organizationHasMoreThanOneShop = !!(currentUser?.organizationShopNumber > 1)
    this.isOrganizationOwner = currentUser?.isOrganizationOwner

    this.allGroups = this.store.selectSnapshot(ShopGroupsState.groups).map((el) => ({
      label: el.name,
      value: el.id
    }))
    this.selectedGroups = [...this.allGroups]
    if (this.allGroups.find((el) => el.value === this.selectedId)) {
      this.groupTabSelected = true
    }

    this.showGroupSwitcher =
      this.showGroup &&
      this.organizationHasMoreThanOneShop &&
      (this.isOrganizationOwner || this.allGroups.length > 0) &&
      this.featuresService.checkHasPermission(OrganizationFeatures.Groups)

    this.form = this.fb.group({
      searchText: [null]
    })

    this.form
      .get('searchText')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        const trimValue = value?.toLowerCase().trim() ?? ''
        if (trimValue === '') {
          this.groupShopsbyRegion(this.allShops)
          this.selectedGroups = [...this.allGroups]
        } else {
          this.groupShopsbyRegion(
            this.allShops.filter((el) => el.label.toLowerCase().includes(trimValue))
          )
          this.selectedGroups = this.allGroups.filter((el) => el.label.toLowerCase().includes(trimValue))
        }
      })

    this.$allShops.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allShops = res.map((el) => ({
        label: el.displayName,
        value: el.id,
        extraInfo: el.regionName
      }))
      if (this.showOrganizationAverage) {
        this.allShops = [
          {
            label: ORGANIZATION_AVERAGE.name,
            value: ORGANIZATION_AVERAGE.id,
            extraInfo: ''
          },
          ...this.allShops
        ]
      }
      this.groupShopsbyRegion(this.allShops)
    })
  }

  groupShopsbyRegion(items: ISelectItem[]) {
    this.selectedShops = groupBy([...items], (x) => x.extraInfo)
  }

  public setFocusOnSearchInput() {
    setTimeout(() => {
      document.getElementById(this.searchInputId)?.focus()
    })
  }
}
