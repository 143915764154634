import { IUserShopAccessRequest } from '../../interfaces/user-shop-access.interface'

export class GetShopUsers {
  static readonly type = '[ShopUsers] Get Shop Users'
  constructor(public shopId: string) {}
}

export class GetShopPendingUsers {
  static readonly type = '[ShopUsers] Get Shop Pending Users'
  constructor(public shopId: string) {}
}

export class UpdateUserShopAccess {
  static readonly type = '[ShopUsers] Update User Shop Access'
  constructor(public payload: IUserShopAccessRequest) {}
}
