import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ICompareWithSelection, ICompareWithType } from '@mg-platform/core/core-data-access'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-compare-with-dialog',
  templateUrl: './compare-with-dialog.component.html',
  styleUrls: ['./compare-with-dialog.component.scss'],
  standalone: false
})
export class CompareWithDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CompareWithDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      singleSelector: boolean
      hiddenSections: ICompareWithType[]
      selections: ICompareWithSelection
      allNationals: ISelectItem[]
      allGroups: ISelectItem[]
      allRegions: ISelectItem[]
      allShops: ISelectItem[]
      allStates: ISelectItem[]
      organizationAverageIsSelected: boolean
    }
  ) {}
}
