import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-add-shop-process',
  templateUrl: './add-shop-process.component.html',
  styleUrls: ['./add-shop-process.component.scss'],
  standalone: false
})
export class AddShopProcessComponent {
  @Input() addNewShop = false
  @Input() cancelBtnLabel = 'Cancel'
  @Output() completed = new EventEmitter()
  @Output() canceld = new EventEmitter()
  activeSection: 'add' | 'focus' | 'service-price' = 'add'
}
