import {
  BenchmarkType,
  CalendarReportType,
  IBenchmarkExpandStatus,
  MarketType
} from '@mg-platform/reports/reports-data-access'
import { ICompareWithSelection } from '../../interfaces/compare-with.interface'
import { IRangeDateInfo, ITableAction, ITableSortInfo } from '@mg-platform/core/core-feature'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { IOrganizationUser } from '@mg-platform/users/users-data-access'

export interface FiltersStateModel {
  shopId: string
  groupId: string
  compareWith: ICompareWithSelection
  marketType: MarketType
  weekNumber: number
  calendarReportType?: CalendarReportType
  calendarViewDate?: number
  benchmarkType: BenchmarkType
  benchmarkStartDate: number
  benchmarkEndDate: number
  benchmarkSecondCompareWith: ICompareWithSelection
  benchmarkThirdCompareWith: ICompareWithSelection
  benchmarkCustomDateInfo: IRangeDateInfo
  benchmarkExpandStatus: IBenchmarkExpandStatus
  compTrendAvgSalesPerBay: boolean | string
  compTrendAvgCarCount: boolean | string
  compTrendAvgTicket: boolean | string
  adminOrganizationsFilters: ITableAction
  adminNotificationsFilters: ITableAction
  adminMarketPricingFilters: ITableAction
  adminShopsFilters: ITableAction
  adminUsersFilters: ITableAction
  salesTrackerDateInfo: IRangeDateInfo
  grossprofitDateInfo: IRangeDateInfo
  adminExcludeBatch: boolean
  rankingsDateInfo: IRangeDateInfo
  salesByCategoryDateInfo: IRangeDateInfo
  salesByCategorySortInfo: ITableSortInfo
  allCountries: ISelectItem[]
  usersTableSortInfo: ITableSortInfo
  userDetialsShops: string[]
  userDetialsInfo: IOrganizationUser
}

export type FilterStateKeys = keyof FiltersStateModel
