<div class="flex-1 flex flex-col overflow-hidden py-xl md:py-base">
  <div class="flex-1 overflow-y-auto px-xl md:px-base beautyScroll">
    <div class="flex">
      <div class="flex-1">
        <div class="text-base font-semibold leading-base">Compare Shop</div>
      </div>
      <div
        *ngIf="!singleSelector"
        class="pl-2xl text-error text-sm leading-sm cursor-pointer"
        (click)="resetSelection()"
      >
        Clear all
      </div>
    </div>

    <form class="mt-xs -mb-sm" [formGroup]="form">
      <mg-input
        [autoFocus]="true"
        [normalInputId]="searchInputId"
        controlName="searchText"
        type="text"
        placeholder="Search"
        icon="search"
        [clearable]="true"
      ></mg-input>
    </form>

    <!-- Group Switch Tab -->
    <ng-container *ngIf="showGroupSwitcher">
      <div class="flex mt-base">
        <div
          class="tab-item"
          [ngClass]="{ '!bg-gray-100': groupTabSelected, '!border-b-0': !groupTabSelected }"
          (click)="groupTabSelected = false"
        >
          <mg-dynamic-icons extraClass="text-txt" [marketType]="marketTypes.shop"></mg-dynamic-icons>
          <div class="tab-title">My Organization</div>
        </div>
        <div
          class="tab-item"
          [ngClass]="{ '!bg-gray-100': !groupTabSelected, '!border-b-0': groupTabSelected }"
          (click)="groupTabSelected = true"
        >
          <mg-dynamic-icons extraClass="text-txt" [marketType]="marketTypes.group"></mg-dynamic-icons>
          <div class="tab-title">My Groups</div>
        </div>
      </div>
    </ng-container>

    <div
      [ngClass]="{
        'section-container flex flex-col gap-lg !mt-0 md:!rounded-tl-0': showGroupSwitcher
      }"
    >
      <!-- My Groups -->
      <div
        [ngClass]="{ 'section-container-noborder': !showGroupSwitcher }"
        *ngIf="(!showGroupSwitcher || groupTabSelected) && showGroupSection"
      >
        <div *ngIf="!showGroupSwitcher" class="section-title flex items-center">
          <mg-dynamic-icons
            extraClass="text-txt mr-base"
            [marketType]="marketTypes.group"
          ></mg-dynamic-icons>
          <span class="ml-base">My Groups</span>
        </div>
        @if (allGroups && allGroups.length > 0) {
          <div class="flex items-start">
            <div class="flex-1 section-pills-container">
              <mg-pill
                *ngFor="let item of visibleGroups"
                [groupId]="item.value"
                [label]="item.label"
                [size]="pillSize"
                [selected]="selections.groups.includes(item.value)"
                (click)="toggleSelection(item.value, 'groups')"
              >
              </mg-pill>
            </div>
            <div
              *ngIf="isOrganizationOwner"
              class="pl-xl pt-xs flex items-center text-primary cursor-pointer"
              [routerLink]="shopGroupsPagePath"
              (click)="canceld.emit()"
            >
              <span class="hidden md:block pr-sm text-base leading-base">Edit</span>
              <svg-icon key="edit" class="!text-sm"></svg-icon>
            </div>
          </div>
        } @else {
          <div class="text-sm leading-sm text-txt-secondary">
            Looks like you haven't set up any groups yet. Go to the
            <span class="text-primary underline cursor-pointer" [routerLink]="shopGroupsPagePath"
              >My Groups</span
            >
            page to get started.
          </div>
        }
      </div>

      <!-- My Organization -->
      <div
        [ngClass]="{ 'section-container-noborder': !showGroupSwitcher }"
        *ngIf="
          (!showGroupSwitcher || !groupTabSelected) &&
          !hiddenSections?.includes('shops') &&
          organizationHasMoreThanOneShop &&
          (visibleShops.length > 0 || showOrganizationAverage)
        "
      >
        <div *ngIf="!showGroupSwitcher" class="section-title flex items-center">
          <mg-dynamic-icons
            extraClass="text-txt mr-base"
            [marketType]="marketTypes.shop"
          ></mg-dynamic-icons>
          <span>My Organization</span>
        </div>
        <div class="section-pills-container">
          <mg-pill
            *ngIf="!singleSelector"
            [label]="
              numberOfSelectedShops === allShops.length + (organizationAverageIsSelected ? 0 : 1)
                ? 'Deselect all'
                : 'Select all'
            "
            [size]="pillSize"
            [selected]="
              numberOfSelectedShops === allShops.length + (organizationAverageIsSelected ? 0 : 1)
            "
            (click)="toggleAllShops()"
          >
          </mg-pill>
          <mg-pill
            *ngIf="showOrganizationAverage && !organizationAverageIsSelected"
            label="My Organization"
            [size]="pillSize"
            [selected]="selections.organizationAverage"
            (click)="setOrganizationAverage()"
          >
          </mg-pill>
          <mg-pill
            *ngFor="let item of showAllShops ? visibleShops : visibleShops.slice(undefined, 6)"
            [label]="item.label"
            [size]="pillSize"
            [selected]="selections.shops.includes(item.value)"
            (click)="toggleSelection(item.value, 'shops')"
          >
          </mg-pill>
          <mg-pill
            *ngIf="visibleShops.length > 6"
            [size]="pillSize"
            [label]="showAllShops ? '-' : '+'"
            (click)="showAllShops = !showAllShops"
          ></mg-pill>
        </div>
      </div>
    </div>

    <!-- Country Swich Tab -->
    <ng-container *ngIf="showCountrySwitcher">
      <div class="flex mt-base">
        <div
          class="tab-item"
          [ngClass]="{
            '!bg-gray-100': selectedCountry === countryCodes.CA,
            '!border-b-0': selectedCountry === countryCodes.USA
          }"
          (click)="selectedCountry = countryCodes.USA; setVisibleStatesAndRegions()"
        >
          <mg-country-flag [countryCode]="countryCodes.USA"></mg-country-flag>
          <div class="tab-title">United States</div>
        </div>
        <div
          class="tab-item"
          [ngClass]="{
            '!bg-gray-100': selectedCountry === countryCodes.USA,
            '!border-b-0': selectedCountry === countryCodes.CA
          }"
          (click)="selectedCountry = countryCodes.CA; setVisibleStatesAndRegions()"
        >
          <mg-country-flag [countryCode]="countryCodes.CA"></mg-country-flag>
          <div class="tab-title">Canada</div>
        </div>
      </div>
    </ng-container>

    <div
      [ngClass]="{
        'section-container flex flex-col gap-lg !mt-0 md:!rounded-tl-0': showCountrySwitcher
      }"
    >
      <!-- State -->
      <div
        *ngIf="!hiddenSections?.includes('states') && visibleStates.length > 0"
        [ngClass]="{ 'section-container-noborder': !showCountrySwitcher }"
      >
        <div
          class="section-title flex items-center"
          [ngClass]="{ '!pt-0': userCountry === countryCodes.ALL }"
        >
          <mg-dynamic-icons
            extraClass="text-txt mr-base"
            [marketType]="marketTypes.state"
          ></mg-dynamic-icons>
          <span>{{ selectedCountry === countryCodes.USA ? 'State' : 'Provences' }}</span>
        </div>
        <div class="section-pills-container">
          <mg-pill
            *ngFor="
              let item of showAllStates
                ? visibleStates
                : visibleStates.slice(undefined, selectedCountry === countryCodes.USA ? 9 : 6)
            "
            [label]="item.label"
            [size]="pillSize"
            [selected]="selections.states.includes(item.value)"
            (click)="toggleSelection(item.value, 'states')"
          >
          </mg-pill>
          <mg-pill
            *ngIf="visibleStates.length > (selectedCountry === countryCodes.USA ? 9 : 6)"
            [size]="pillSize"
            [label]="showAllStates ? '-' : '+'"
            (click)="showAllStates = !showAllStates"
          ></mg-pill>
        </div>
      </div>

      <!-- Regional -->
      <div
        *ngIf="!hiddenSections?.includes('regions') && visibleRegions.length > 0"
        [ngClass]="{ 'section-container-noborder': !showCountrySwitcher }"
      >
        <div
          class="section-title flex items-center"
          [ngClass]="{ '!mt-0': userCountry === countryCodes.ALL }"
        >
          <mg-dynamic-icons
            extraClass="text-txt mr-base"
            [marketType]="marketTypes.regional"
          ></mg-dynamic-icons>
          <span>{{ selectedCountry === countryCodes.USA ? 'Regions' : 'Territories' }}</span>
        </div>
        <div class="section-pills-container">
          <mg-pill
            *ngFor="let item of showAllRegions ? visibleRegions : visibleRegions.slice(undefined, 7)"
            [label]="item.label"
            [size]="pillSize"
            [selected]="selections.regions.includes(item.value)"
            (click)="toggleSelection(item.value, 'regions')"
          >
          </mg-pill>
          <mg-pill
            *ngIf="visibleRegions.length > 7"
            [size]="pillSize"
            [label]="showAllRegions ? '-' : '+'"
            (click)="showAllRegions = !showAllRegions"
          ></mg-pill>
        </div>
      </div>
    </div>

    <!-- National -->
    <div
      class="section-container-noborder"
      *ngIf="!hiddenSections?.includes('nationals') && visibleNationals.length > 0"
    >
      <div class="section-title flex items-center">
        <mg-dynamic-icons
          extraClass="text-txt mr-base"
          [marketType]="marketTypes.national"
        ></mg-dynamic-icons>
        <span>National</span>
      </div>
      <div class="section-pills-container">
        <mg-pill
          *ngFor="let item of visibleNationals"
          [label]="item.label"
          [size]="pillSize"
          [selected]="selections.nationals.includes(item.value)"
          (click)="toggleSelection(item.value, 'nationals')"
        >
        </mg-pill>
      </div>
    </div>
  </div>

  <div *ngIf="!singleSelector" class="md:hidden flex flex-col mt-lg px-xl">
    <mg-button
      class="mb-base flex-1"
      size="small"
      (click)="saveSelections()"
      [disabled]="numberOfSelection < 1"
    >
      <ng-container *ngTemplateOutlet="applyTextTemplate"></ng-container>
    </mg-button>
    <mg-button class="flex-1" size="small" variant="secondary" (click)="canceld.emit()">
      Cancel
    </mg-button>
  </div>

  <div *ngIf="!singleSelector" class="hidden md:flex items-center justify-end mt-base px-base">
    <mg-button class="mr-2xl" variant="ghost" size="small-shrink" (click)="canceld.emit()">
      Cancel
    </mg-button>
    <mg-button
      variant="ghost"
      size="small-shrink"
      (click)="saveSelections()"
      [disabled]="numberOfSelection < 1"
    >
      <ng-container *ngTemplateOutlet="applyTextTemplate"></ng-container>
    </mg-button>
  </div>
</div>

<ng-template #applyTextTemplate>
  @if (numberOfSelection < 1) {
    No selection
  } @else {
    Apply {{ numberOfSelection }} selection{{ numberOfSelection > 1 ? 's' : '' }}
  }
</ng-template>
