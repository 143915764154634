import { environment } from '@mg-platform/core/core-util'

export const USERS_API_URL = {
  // Users
  base: (userId: string) => `${environment.apiUrl}/users/${userId}`,
  login: `${environment.apiUrl}/users/login`,
  refreshToken: `${environment.apiUrl}/users/refresh`,
  loginAutovitals: `${environment.apiUrl}/users/login-autovitals`,
  register: `${environment.apiUrl}/users/register`,
  logout: `${environment.apiUrl}/users/logout`,
  me: `${environment.apiUrl}/users/me`,
  requestPasswordReset: `${environment.apiUrl}/users/requestpasswordreset`,
  resetPassword: `${environment.apiUrl}/users/resetpassword`,
  updateProfile: `${environment.apiUrl}/users`,
  updatePassword: `${environment.apiUrl}/users/password`,
  preferences: `${environment.apiUrl}/users/preferences`,
  getAllUsers: `${environment.apiUrl}/users/organization-users`,
  getOneUser: `${environment.apiUrl}/users/get-organization-user`,
  deleteUser: `${environment.apiUrl}/users/delete-user`,
  validateEmail: `${environment.apiUrl}/users/validate-email`,
  setUserPermissions: `${environment.apiUrl}/users/set-user-permissions`,
  getRegistrationStatus: `${environment.apiUrl}/users/get-registration-status`,

  // Admin Users
  baseAdminUser: (userId: string) => `${environment.apiUrl}/admin/users/${userId}`,
  getAllAdminUsers: `${environment.apiUrl}/admin/filters/users`,
  exportAllAdminUsers: `${environment.apiUrl}/admin/filters/users/export`
}
