<mg-section-title>
  <span slot="title">
    @if (selectedDateInfo.periodType && selectedDateInfo.periodType !== periodType.Custom) {
      <span>
        {{ periodTypeToLabel[selectedDateInfo.periodType] }}
      </span>
      <span> ({{ dateService.momentCalculatedFormattedPeriod(selectedDateInfo.rangeDate) }}) </span>
    } @else {
      {{ dateService.momentCalculatedFormattedPeriod(selectedDateInfo.rangeDate) }}
    }
  </span>
</mg-section-title>
