import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { FullScreenModalConfig, MobileMaxWidth, ORGANIZATION_AVERAGE } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { CompareWithDialogComponent } from '../compare-with-dialog/compare-with-dialog.component'
import { Store } from '@ngxs/store'
import {
  CompareWithInitialValue,
  FiltersState,
  ICompareWithSelection,
  ICompareWithType,
  IStateWithRegionInfo,
  StatesState
} from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { cloneDeep } from 'lodash'
import { ISelectItem } from '@mg-platform/core/core-ui'
import { IShop, ShopGroupsState, ShopsState } from '@mg-platform/shops/shops-data-access'
import { TrackingService } from '../../services/tracking.services'

@UntilDestroy()
@Component({
  selector: 'mg-compare-filter',
  templateUrl: './compare-filter.component.html',
  styleUrls: ['./compare-filter.component.scss'],
  standalone: false
})
export class CompareFilterComponent implements OnInit, OnChanges {
  @Input() currentSelectedId: string
  @Input() compareWith: ICompareWithSelection
  @Input() singleSelector = false
  @Input() hiddenSections: ICompareWithType[] = []
  @Input() clearable = false

  organizationAverageIsSelected = false

  $allShops: Observable<IShop[]> = inject(Store).select(ShopsState.allShops)
  allShops: ISelectItem[] = []
  allSelectedShops: ISelectItem[] = []

  $allStates: Observable<IStateWithRegionInfo[]> = inject(Store).select(StatesState.states)
  allStates: ISelectItem[] = []

  $allRegions: Observable<IStateWithRegionInfo[]> = inject(Store).select(StatesState.regions)
  allRegions: ISelectItem[] = []
  allSelectedGroups: ISelectItem[] = []

  selections: ICompareWithSelection
  allNationals: ISelectItem[] = []
  allGroups: ISelectItem[] = []

  selectedNames: string[] = []

  @Output() selectionsChanged = new EventEmitter<ICompareWithSelection>()

  window = window
  mobileMaxWidth = MobileMaxWidth

  constructor(
    private dialogService: MatDialog,
    private trackingService: TrackingService,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.$allShops.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allShops = res.map((el) => ({
        label: el.displayName,
        value: el.id
      }))
      this.allSelectedShops = [...this.allShops]
    })
    this.$allStates.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allStates = res.map((el) => ({
        label: el.stateName,
        value: el.stateId,
        extraInfo: el.countryCode
      }))
    })
    this.$allRegions.pipe(untilDestroyed(this)).subscribe((res) => {
      if (!res || res.length < 1) {
        return
      }
      this.allRegions = res.map((el) => ({
        label: el.regionName,
        value: el.regionId,
        extraInfo: el.countryCode
      }))
    })
    this.allNationals = this.store.selectSnapshot(FiltersState.allCountries)
    this.allGroups = this.store.selectSnapshot(ShopGroupsState.groups).map((el) => ({
      label: el.name,
      value: el.id
    }))
    this.allSelectedGroups = [...this.allGroups]
  }

  ngOnChanges(changes: SimpleChanges): void {
    const compareWithChanges = changes['compareWith']
    if (compareWithChanges?.currentValue) {
      this.setSelections()
    }

    const currentSelectedIdChanges = changes['currentSelectedId']
    if (currentSelectedIdChanges && currentSelectedIdChanges.currentValue) {
      this.selectionsChanged.emit({
        ...(this.compareWith ?? {}),
        organizationAverage:
          currentSelectedIdChanges.currentValue === ORGANIZATION_AVERAGE.id
            ? false
            : !!this.compareWith?.organizationAverage,
        shops: (this.compareWith?.shops ?? []).filter(
          (el) => el !== currentSelectedIdChanges.currentValue
        ),
        groups: (this.compareWith?.groups ?? []).filter(
          (el) => el !== currentSelectedIdChanges.currentValue
        )
      })

      if (currentSelectedIdChanges.currentValue === ORGANIZATION_AVERAGE.id) {
        this.organizationAverageIsSelected = true
      } else {
        this.organizationAverageIsSelected = false
      }

      setTimeout(() => {
        this.allSelectedShops = this.allShops.filter((el) => {
          return el.value !== currentSelectedIdChanges.currentValue
        })
        this.allSelectedGroups = this.allGroups.filter((el) => {
          return el.value !== currentSelectedIdChanges.currentValue
        })
      })
    }
  }

  setSelections() {
    this.selections = cloneDeep(this.compareWith)
    setTimeout(() => {
      this.selectedNames = this.trackingService.generateCompareWithSelectedNames(this.compareWith)
    })
  }

  openShopSelectorDialog() {
    this.dialogService
      .open(CompareWithDialogComponent, {
        ...FullScreenModalConfig,
        data: {
          singleSelector: this.singleSelector,
          hiddenSections: this.hiddenSections,
          selections: this.selections,
          allNationals: this.allNationals,
          allGroups: this.allSelectedGroups,
          allRegions: this.allRegions,
          allShops: this.allSelectedShops,
          allStates: this.allStates,
          organizationAverageIsSelected: this.organizationAverageIsSelected
        }
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        if (value) {
          this.selectionsChanged.emit(value)
        }
        this.setSelections()
      })
  }

  onClickIcon(event: MouseEvent) {
    if (this.clearable && this.selectedNames.length > 0) {
      event.stopPropagation()
      this.selectionsChanged.emit({ ...CompareWithInitialValue })
    }
  }
}
