import { Component, Inject, OnInit } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ISelectItem } from '@mg-platform/core/core-ui'
import {
  SetShopFocus,
  ShopFocusType,
  ShopFocusTypeToLabel,
  ShopsState
} from '@mg-platform/shops/shops-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { finalize } from 'rxjs'

@UntilDestroy()
@Component({
  selector: 'mg-shop-focus-dialog',
  templateUrl: './shop-focus-dialog.component.html',
  styleUrls: ['./shop-focus-dialog.component.scss'],
  standalone: false
})
export class ShopFocusDialogComponent implements OnInit {
  form: UntypedFormGroup
  isProcessing = false
  shopFocusItems: ISelectItem[] = Object.values(ShopFocusType)
    .filter((el) => el !== ShopFocusType.NotSet)
    .map((el) => ({ label: ShopFocusTypeToLabel[el], value: el }))

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store,
    public dialogRef: MatDialogRef<ShopFocusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { focus?: ShopFocusType },
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      focusType: [this.data?.focus ?? null, [Validators.required]]
    })
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.invalid || this.isProcessing) {
      return
    }

    const shopId = this.store.selectSnapshot(ShopsState.shop)?.id

    this.isProcessing = true
    this.store
      .dispatch(new SetShopFocus(this.form.value, shopId))
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isProcessing = false
        })
      )
      .subscribe(() => {
        this.toastrService.success('Shop focus updated successfully.')
        this.dialogRef.close(this.form.get('focusType')?.value)
      })
  }
}
