import { Component, HostListener, inject, Input, OnInit } from '@angular/core'
import { Store } from '@ngxs/store'
import { Router } from '@angular/router'
import { IUser, Logout, ReferralType, UserAccountState } from '@mg-platform/users/users-data-access'
import { Observable } from 'rxjs'
import { FullScreenModalConfig, MobileMaxWidth } from '@mg-platform/core/core-util'
import { IHeaderRouteInfo } from './main-header.interface'
import { ResetShopState } from '@mg-platform/shops/shops-data-access'
import { ResetFilters } from '@mg-platform/core/core-data-access'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { MatDialog } from '@angular/material/dialog'
import { MenuHeaderItemsDialogComponent } from '../menu-header-items-dialog/menu-header-items-dialog.component'
import { MenuChangeModeDialogComponent } from '../menu-change-mode-dialog/menu-change-mode-dialog.component'

@UntilDestroy()
@Component({
  selector: 'mg-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  standalone: false
})
export class MainHeaderComponent implements OnInit {
  @Input() forAdmin = false
  @Input() mainMenuRoutes: IHeaderRouteInfo[]
  @Input() profileMenuRoutes: IHeaderRouteInfo[]
  currentUser$: Observable<IUser> = inject(Store).select(UserAccountState.currentUser)
  currentUser: IUser

  referralType = ReferralType

  constructor(
    private store: Store,
    private router: Router,
    private dialogService: MatDialog
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (!this.forAdmin && event.target.innerWidth >= MobileMaxWidth) {
      this.dialogService.closeAll()
    }
  }

  ngOnInit(): void {
    this.currentUser$.pipe(untilDestroyed(this)).subscribe((res) => {
      this.currentUser = res
    })
  }

  logout() {
    this.store.dispatch(new Logout()).subscribe(() => {
      this.store.dispatch([
        new ResetShopState(),
        new ResetFilters(['shopId', 'marketType', 'weekNumber', 'compareWith'])
      ])
      this.router.navigateByUrl('/login')
    })
  }

  openMenuItemsDialog(routes: IHeaderRouteInfo[], showLogout: boolean): void {
    this.dialogService.open(MenuHeaderItemsDialogComponent, {
      ...FullScreenModalConfig,
      panelClass: ['fullscreen-modal', 'animate__animated', 'animate__slideInRight', 'animate__fast'],
      data: {
        routes,
        showLogout
      }
    })
  }

  openMenuChangeModeDialog(): void {
    this.dialogService.open(MenuChangeModeDialogComponent, {
      ...FullScreenModalConfig,
      panelClass: ['fullscreen-modal', 'animate__animated', 'animate__slideInRight', 'animate__fast']
    })
  }
}
